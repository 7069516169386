body {
  background: rgb(255, 255, 255);
}

.inputForm {
  .header {
    justify-content: center;
    text-align: center;

    .logo {
      width: 10vh;
      height: 10vh;
    }

    .companyName {
      margin: 0;
      margin-bottom: 2%;
      font-weight: 600;
      color: #009e82;
    }

    .webName {
      margin: 0;
      // margin-top: 20;
      color: #009e82;
    }

    .instruction {
      margin: 0;
      color: #009e82;
    }
  }

  .input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .icon {
      color: #009e82;
    }
  }

  .loginError {
    width: 92%;
    margin-left: 25%;
  }

  .submit {
    width: 50%;
    margin-left: 25%;

    .submitSize {
      width: 100%;
      font-weight: 500;
    }
  }

  .title_icon {
    font-size: 45px;
    color: #009e82;
    margin: 20px;
  }

  .forgetPs {
    border: none;
    box-shadow: none;
    background-color: transparent;
    float: right;
    color: #009e82;
    font-weight: 500;
  }

  .forget_header {
    justify-content: center;
    text-align: center;

    .forget_icon {
      font-size: 5vh;
    }

    .forget_stitle {
      margin: 0;
      margin-top: 3%;
      font-weight: 300;
      color: #009e82;
    }

    .forget_title {
      margin: 0;
      margin-top: 3%;
      color: #009e82;
    }
  }

  .forget_input {
    justify-content: center;
    width: 100%;

    .forget_icon {
      color: #009e82;
    }
  }
}

.photo {
  width: 100%;
  height: 100vh;
  object-fit: cover;

  // object-fit: cover;
}

@media (max-width: 1000px) {
  .inputForm {
    height: 100vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    // position: inherit;
    background-image: url(../assets/LoginBg.png);
  }

  .box {
    margin-top: 25vh;
    // margin-bottom: 25vh;
    margin-left: 10%;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.66);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    line-height: 1;
    height: 50%;
    padding: 3%;
  }
}
