body {
  background: rgb(255, 255, 255);
}

.inputForm {
  .header {
    justify-content: center;
    text-align: center;

    .logo {
      width: 10vh;
      height: 10vh;
    }

    .title {
      margin: 0;
      margin-bottom: 2%;
      font-weight: 600;
      color: #009e82;
    }
  }

  .inputCol {
    width: 100%;
  }

  .icon {
    color: #009e82;
  }

  .submitSize {
    width: 100%;
    font-weight: 500;
  }

  .titleIcon {
    font-size: 45px;
    color: #009e82;
    margin: 20px;
  }

}

.photo {
  width: 100%;
  height: 100vh;
}

@media (max-width: 960px) {
  .inputForm {
    height: 100vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    background-image: url(../assets/LoginBg.png);
  }

  .box {
    margin-top: 25vh;
    margin-bottom: 25vh;
    margin-left: 10%;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.66);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    line-height: 1;
    height: 50%;
    padding: 3%;
  }
}