.htmlContent img {
    max-width: 80%;
    max-height: 80%;
    align-items: center;
    justify-content: center;
}
.edtior iframe {
    max-width: 100%;
    height: 100%;
    width: 100%;
}
