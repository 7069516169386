/* @import url("../node_modules/leaflet/dist/leaflet.css"); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
  font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
  font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22px";
  font-size: 22px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
  font-size: 24px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28px";
  font-size: 28px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
  font-size: 32px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: "48px";
  font-size: 48px !important;
}
.ql-picker-label[data-value="12px"]::before {
  content: "12px" !important;
}
.ql-picker-label[data-value="14px"]::before {
  content: "14px" !important;
}
.ql-picker-label[data-value="16px"]::before {
  content: "16px" !important;
}
.ql-picker-label[data-value="18px"]::before {
  content: "18px" !important;
}
.ql-picker-label[data-value="20px"]::before {
  content: "20px" !important;
}
.ql-picker-label[data-value="22px"]::before {
  content: "22px" !important;
}
.ql-picker-label[data-value="24px"]::before {
  content: "24px" !important;
}
.ql-picker-label[data-value="28px"]::before {
  content: "28px" !important;
}
.ql-picker-label[data-value="32px"]::before {
  content: "32px" !important;
}
.ql-picker-label[data-value="48px"]::before {
  content: "48px" !important;
}
